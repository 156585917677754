// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import TileGallery from '../components/tileGallery'
// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout pageTitle="Alex West">
      <TileGallery galleryId="Overview"></TileGallery>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Alex West</title>
